import React from 'react'

import Layout                                  from '../components/shared/layout'
import SEO                                     from '../components/shared/seo'
import { Link }                                from 'gatsby'
import { Button, Container, Grid, Typography } from '@material-ui/core'

const NotFoundPage = () => (
    <Layout helmetData={{pageTitle: '404 Not Found', metaDescription: 'Level 22 Chambers, Sydney Australia', slug:'404'}} backgroundColor={'#FFF'} backgroundImage={''}>
        <SEO title="404: Not found" />
        <Container maxWidth={'lg'}>
            <Grid container justify={'center'}>
                <Grid item>
                    <Typography component={'h1'} variant={'h1'}>PAGE NOT FOUND</Typography>
                    <p>Sorry, the page you are looking for does not exist.</p>
                    <Typography variant={'body1'} component={'p'}>
                        Let us know what you were trying to do: <a style={{color: 'goldenrod'}}
                        href={'mailto:clerk@level22.com.au'}>clerk@level22.com.au</a>
                    </Typography>

                    <Link to={'/'}><Button variant={'outlined'} style={{ marginTop: '2rem' }}>Return Home</Button></Link>
                </Grid>
            </Grid>
        </Container>
    </Layout>
)

export default NotFoundPage
